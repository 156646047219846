<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="page-title mb-2">{{ $t("SIDE_MENU.EXPLORER") }}</h4>
      <TextField
        a-tag="explorerSearchButton"
        type="search"
        name="search"
        v-model.trim="search"
        :placeholder="$t('GLOBAL_SEARCH')"
      />
    </div>
    <div class="main-card">
      <div class="text-center p-3" v-if="explorerLoading">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <div v-else>
        <div v-if="filteredExplorer.length">
          <b-table
            a-tag="explorerTable"
            class="custom-table"
            responsive
            :items="filteredExplorer"
            :fields="fields"
            :per-page="perPage"
            :current-page="page"
          >
            <template #cell(name)="data">
              <div
                class="d-flex align-item-center name-container pointer"
                @click="handleRedirectExplorerInfo(data.item.id)"
              >
                <div class="asset">
                  <icon-crypto :coinname="data.item.symbol" format="svg" />
                  <div>
                    <h5>{{ data.item.name }}</h5>
                    <h6>{{ data.item.symbol }}</h6>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(price)="data">
              <div>${{ handleRoundNumber(data.item.price) }}</div>
            </template>
            <template #cell(market_cap)="data">
              <div>${{ handleRoundNumber(data.item.market_cap) }}</div>
            </template>
            <template #cell(volume_24h)="data">
              <div>${{ handleRoundNumber(data.item.volume_24h) }}</div>
            </template>
          </b-table>
        </div>
        <div class="text-center" v-else>
          <h6 class="py-3">
            {{ $t("GLOBAL_NO_MESSAGE") }}
          </h6>
        </div>

        <pagination
          :current-page="page"
          :per-page="perPage"
          :total-items="totalItems"
          @onPageChange="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getExplorerRequest } from "@/api/explorer";
import Pagination from "@/components/Shared/Pagination";
import { handleRoundNumber } from "@/helpers";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      handleRoundNumber,
      explorerLoading: false,
      filteredExplorer: [],
      explorer: [],
      fields: [
        { key: "name", sortable: true, label: this.$t("EXPLORER.NAME") },
        { key: "price", sortable: true, label: this.$t("EXPLORER.PRICE") },
        {
          key: "market_cap",
          sortable: true,
          label: this.$t("EXPLORER.MARKET_CAP"),
        },
        {
          key: "volume_24h",
          sortable: true,
          label: this.$t("EXPLORER.VOLUME_24"),
        },
      ],
      page: 1,
      perPage: 10,
      search: "",
    };
  },
  created() {
    this.handleLoadExplorer();
  },
  computed: {
    totalItems() {
      return this.filteredExplorer.length;
    },
  },
  watch: {
    search() {
      this.filteredExplorer = this.explorer.filter((explore) =>
        explore.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    handleLoadExplorer() {
      this.explorerLoading = true;
      this.ApiService(getExplorerRequest())
        .then(({ data }) => {
          this.filteredExplorer = data.data;
          this.explorer = data.data;
        })
        .finally(() => {
          this.explorerLoading = false;
        });
    },

    handleRedirectExplorerInfo(id) {
      this.$router.push(`/explorer/${id}`);
    },
    changePage(page) {
      this.page = page;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
